import { isDefined } from "@clipboard-health/util-ts";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import constate from "constate";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";

import { useAgentPreferences } from "../../../Agents/api/useAgentPreferences";
import { DEFAULT_DISTANCE_IN_MILES_FILTER } from "./constants";
import {
  type ShiftBookingTypeFilterOption,
  type ShiftTimeSlotFilterOption,
  type UserFilters,
} from "./types";
import { useStoredUserFilters } from "./useStoredUserFilters";

interface UserFiltersContext extends UserFilters {
  appliedFiltersCount: number;
  pickedSingleDate?: string;
  clearDates: () => void;
  clearFilters: () => void;
  setPickedSingleDate: (date?: string) => void;
  setDates: (dates: string[]) => void;
  setDistance: (value: number) => void;
  setFilters: (filters: Partial<UserFilters>) => void;
  setLicenses: (value?: string[]) => void;
  setShiftBookingTypes: (value?: ShiftBookingTypeFilterOption[]) => void;
  setShiftTimeSlots: (value?: ShiftTimeSlotFilterOption[]) => void;
  availableLicenses: string[];
}

function parseAvailableLicenses(selectedLicenses: string[], availableLicenses: string[]) {
  const availableLicensesSet = new Set(availableLicenses);
  return selectedLicenses.filter((license) => availableLicensesSet.has(license));
}

function useShiftDiscoveryUserFilters(): UserFiltersContext {
  const [dates, setDates] = useState<string[]>([]);
  /**
   * This is a single date picked by the user from the calendar picker.
   * It is stored separately as it doesn't explicitly filter the open shifts, but rather scrolls the user down to it.
   */
  const [pickedSingleDate, setPickedSingleDate] = useState<string>();

  const clearDates = () => {
    setDates([]);
  };

  const { filters: agentPreferences, setFilters: setAgentPreferences } = useAgentPreferences();
  const worker = useDefinedWorker();

  const { distance = DEFAULT_DISTANCE_IN_MILES_FILTER } = agentPreferences;

  const setDistance = (value: number) => {
    setAgentPreferences({ ...agentPreferences, distance: value });
  };

  // Dates don't need to be tracked in local storage as they change frequently.
  // Distance also doesn't need to be tracked in local storage because
  // it's already in the agent preferences.
  const {
    storedFilters,
    modifiedStoredFiltersCount,
    setStoredFilters,
    setStoredFilter,
    clearStoredFilters,
  } = useStoredUserFilters();

  // Make sure stored licenses are still available
  useEffect(() => {
    const availableLicenses = worker.licensesData.map((license) => license.qualification);
    const parsedLicenses = parseAvailableLicenses(storedFilters.licenses ?? [], availableLicenses);

    if (!isEqual(parsedLicenses, storedFilters.licenses)) {
      setStoredFilter("licenses", parsedLicenses);
    }
  }, [worker, storedFilters, setStoredFilter]);

  const setLicenses = (values?: string[]) => {
    setStoredFilter("licenses", values ?? []);
  };

  const setShiftBookingTypes = (values?: ShiftBookingTypeFilterOption[]) => {
    setStoredFilter("shiftBookingTypes", values ?? []);
  };

  const setShiftTimeSlots = (values?: ShiftTimeSlotFilterOption[]) => {
    setStoredFilter("shiftTimeSlots", values ?? []);
  };

  const setFilters = (filters: Partial<UserFilters>) => {
    const { dates, distance: newDistance, ...rest } = filters;

    if (isDefined(dates)) {
      setDates(dates);
    }

    if (isDefined(newDistance)) {
      setDistance(newDistance);
    }

    if (Object.keys(rest).length > 0) {
      setStoredFilters((storedFilters) => ({ ...storedFilters, ...rest }));
    }
  };

  const clearFilters = () => {
    clearDates();
    setDistance(DEFAULT_DISTANCE_IN_MILES_FILTER);
    clearStoredFilters();
  };

  return {
    appliedFiltersCount: modifiedStoredFiltersCount,
    clearFilters,
    setFilters,
    setPickedSingleDate,
    pickedSingleDate,
    /**
     * Date strings in the format of "yyyy-MM-dd"
     */
    dates,
    setDates,
    clearDates,
    distance,
    setDistance,
    setLicenses,
    availableLicenses: worker.licensesData.map((license) => license.qualification),
    ...storedFilters,
    setShiftBookingTypes,
    setShiftTimeSlots,
  };
}

export const [ShiftDiscoveryUserFiltersProvider, useShiftDiscoveryUserFiltersContext] = constate(
  useShiftDiscoveryUserFilters
);
