// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/max-dependencies */
import { Title, type UseModalState } from "@clipboard-health/ui-react";
import { Box, DialogContent, Stack } from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { Divider } from "@src/appV2/redesign/components/Divider";
import { ShiftBookingType } from "@src/appV2/ShiftBlocks/utils";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { uniq } from "lodash";
import { Controller } from "react-hook-form";

import { BottomSheet } from "../../../components/BottomSheet";
import { CounterText } from "../../../components/CounterText";
import { PageHeaderWrapper } from "../../../components/PageHeaderWrapper";
import { TimeSlot } from "../../../Shift/Open/types";
import { LicensesFilterButtonGroup } from "../LicensesFilterButtonGroup";
import { ShiftBookingTypeButtonGroup } from "../ShiftBookingTypeButtonGroup";
import { ShiftTimeSlotButtonGroup } from "../ShiftTimeSlotButtonGroup";
import { useIsShiftBookingTypeFilterEnabled } from "../useIsShiftBookingTypeFilterEnabled";
import { DistanceFilter } from "./DistanceFilter";
import { FilterSectionWrapper } from "./FilterSectionWrapper";
import { FiltersBottomSheetFooter } from "./Footer";
import { useFilteredOpenShiftsCount } from "./useFilteredOpenShiftsCount";
import { useFiltersForm } from "./useFiltersForm";

interface ShiftDiscoveryFiltersBottomSheetProps {
  modalState: UseModalState;
}

export function ShiftDiscoveryFiltersBottomSheet(props: ShiftDiscoveryFiltersBottomSheetProps) {
  const { modalState } = props;

  const worker = useDefinedWorker();

  // There might be multiple licenses with the same name but different states
  // https://github.com/ClipboardHealth/cbh-mobile-app/pull/3849
  const workerLicenses = uniq(
    worker?.licensesData?.map((license) => license.qualification) ?? []
  ).sort();

  const { form, changedFiltersCount, resetFilters, cancelFilters, submitFilters } =
    useFiltersForm();

  const formValues = form.watch();

  const { count: filteredOpenShiftsCount, isLoading: filteredOpenShiftsIsLoading } =
    useFilteredOpenShiftsCount(formValues);

  const isShiftBookingTypeFilterEnabled = useIsShiftBookingTypeFilterEnabled();

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <FiltersBottomSheetFooter
          showClearFormButton={changedFiltersCount > 0}
          openShiftsCount={filteredOpenShiftsCount}
          openShiftsIsLoading={filteredOpenShiftsIsLoading}
          onClear={() => {
            logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_FILTERS_CLEARED, {
              trigger: "Filters Modal",
              shiftCount: filteredOpenShiftsCount,
            });
            resetFilters();
          }}
          onCancel={() => {
            logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_FILTERS_CLOSED, {
              shiftCount: filteredOpenShiftsCount,
            });
            cancelFilters();
            modalState.closeModal();
          }}
          onSubmit={() => {
            logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_FILTERS_APPLIED, {
              trigger: "Filters Modal",
              shiftCount: filteredOpenShiftsCount,
              distance: formValues.distance,
              timeSlotsAll: formValues.shiftTimeSlots.length === 0,
              timeSlots:
                formValues.shiftTimeSlots.length === 0
                  ? [TimeSlot.AM, TimeSlot.PM, TimeSlot.NOC]
                  : formValues.shiftTimeSlots,
              licensesAll: formValues.licenses.length === 0,
              licenses: formValues.licenses.length === 0 ? workerLicenses : formValues.licenses,
              bookingTypesEnabled: isShiftBookingTypeFilterEnabled,
              bookingTypesAll: formValues.shiftBookingTypes.length === 0,
              bookingTypes:
                formValues.shiftBookingTypes.length === 0
                  ? [ShiftBookingType.PER_DIEM, ShiftBookingType.BLOCK_BOOKING]
                  : formValues.shiftBookingTypes,
            });
            submitFilters();
            modalState.closeModal();
          }}
        />
      }
    >
      <DialogContent sx={{ paddingBottom: 12 }}>
        <Stack spacing={8}>
          <PageHeaderWrapper variant="tertiary">
            <CounterText count={changedFiltersCount} suffix="applied">
              <Title variant="h4" component="h4">
                Filters
              </Title>
            </CounterText>
          </PageHeaderWrapper>

          {isShiftBookingTypeFilterEnabled && (
            <>
              <Controller
                control={form.control}
                name="shiftBookingTypes"
                render={({ field }) => (
                  <FilterSectionWrapper sectionTitle="Shift Types">
                    <ShiftBookingTypeButtonGroup value={field.value} onChange={field.onChange} />
                  </FilterSectionWrapper>
                )}
              />
              <Divider />
            </>
          )}

          <Controller
            control={form.control}
            name="shiftTimeSlots"
            render={({ field }) => (
              <FilterSectionWrapper sectionTitle="Shift Times">
                <ShiftTimeSlotButtonGroup value={field.value} onChange={field.onChange} />
              </FilterSectionWrapper>
            )}
          />

          <Divider />

          <Controller
            control={form.control}
            name="distance"
            render={({ field }) => <DistanceFilter value={field.value} onChange={field.onChange} />}
          />

          <Divider />

          <Controller
            control={form.control}
            name="licenses"
            render={({ field }) => {
              // Safety check to avoid showing an empty filter, likely never to happen.
              if (workerLicenses.length === 0) {
                // using empty box here as null causes typescript to scream
                return <Box />;
              }

              return (
                <FilterSectionWrapper sectionTitle="Licenses">
                  <LicensesFilterButtonGroup
                    value={field.value}
                    availableLicenses={workerLicenses}
                    onChange={field.onChange}
                  />
                </FilterSectionWrapper>
              );
            }}
          />
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
