import { ModalStatus, type UseModalState, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { useFacilityNotes } from "@src/appV2/Facilities/api/useFacilityNotes";
import { MuiThemeProvider } from "@src/appV2/lib/Theme";
import { useGetWorkplaceQuiz } from "@src/appV2/WorkplaceQuiz/api/useGetWorkplaceQuiz/useGetWorkplaceQuiz";
import { QuizPassedDialogWithShift } from "@src/appV2/WorkplaceQuiz/Dialogs/QuizPassedDialogWithShift";
import { WorkplaceRulesWithQuiz } from "@src/appV2/WorkplaceQuiz/WorkplaceRulesWithQuiz/WorkplaceRulesWithQuiz";

import { SHIFT_DISCOVERY_SHIFT_MODAL_PATH } from "../ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "../ShiftDiscovery/useShiftModalsDataContext";

interface LegacyQuizFlowContainerProps {
  modalState: UseModalState;
  shiftId?: string;
  shiftStart?: string;
  shiftEnd?: string;
  shiftTime?: number;
  workplaceId?: string;
  workplaceName?: string;
  workplaceTimezone?: string;
}

/**
 * This component is used to display the quiz flow in the legacy way.
 * This will be rewritten to a new designs soon, it's made like this just to unblock booking flow.
 */
export function LegacyQuizFlowContainer(props: LegacyQuizFlowContainerProps) {
  const {
    modalState,
    shiftId,
    shiftStart,
    shiftEnd,
    shiftTime,
    workplaceId,
    workplaceName,
    workplaceTimezone,
  } = props;

  const { data: quizData } = useGetWorkplaceQuiz(workplaceId ?? "", {
    enabled: isDefined(workplaceId),
  });

  const quiz = quizData?.data?.[0];

  const { data: facilityNotes } = useFacilityNotes(workplaceId ?? "", {
    enabled: isDefined(workplaceId),
  });

  const { navigateToModal } = useShiftModalsDataContext();

  // internal modal states are used to maintain separated modals. In new designs this will be a single modal
  const quizPassedModalState = useModalState();
  const quizModalState = useModalState(ModalStatus.OPEN);

  return (
    <MuiThemeProvider>
      {isDefined(quiz) && isDefined(facilityNotes) && isDefined(workplaceName) && (
        <WorkplaceRulesWithQuiz
          facilityName={workplaceName}
          facilityNotes={facilityNotes}
          quizRulesDialog={quizModalState}
          quiz={quiz}
          logMetadata={{
            shiftId,
            source: "booking",
          }}
          onQuizPassed={() => {
            quizPassedModalState.openModal();
          }}
          onCancel={() => {
            modalState.closeModal();
          }}
        />
      )}

      {isDefined(workplaceName) &&
        isDefined(workplaceTimezone) &&
        isDefined(shiftId) &&
        isDefined(shiftStart) &&
        isDefined(shiftEnd) &&
        isDefined(shiftTime) && (
          <QuizPassedDialogWithShift
            workplace={{
              name: workplaceName,
              tmz: workplaceTimezone,
            }}
            shift={{
              start: shiftStart,
              end: shiftEnd,
              time: shiftTime,
            }}
            modalState={quizPassedModalState}
            onBookShift={async () => {
              navigateToModal(SHIFT_DISCOVERY_SHIFT_MODAL_PATH, {
                shiftId,
              });
            }}
            onCancel={() => {
              modalState.closeModal();
              quizPassedModalState.closeModal();
              quizModalState.closeModal();
            }}
          />
        )}
    </MuiThemeProvider>
  );
}
