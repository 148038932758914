import { ToggleButton } from "@mui/material";

import { FiltersToggleButtonGroup } from "./BottomSheet/ToggleButtonGroup";
import { type FiltersFormValues } from "./BottomSheet/types";
import { FILTER_ALL_VALUE, useFilterSelectedOptions } from "./BottomSheet/useFilterSelectedOptions";

interface LicensesFilterButtonGroupProps {
  value?: FiltersFormValues["licenses"];
  onChange: (value: FiltersFormValues["licenses"]) => void;
  availableLicenses: string[];
}

export function LicensesFilterButtonGroup(props: LicensesFilterButtonGroupProps) {
  const { value, onChange, availableLicenses } = props;

  const { selectedOptions, onChangeSelection } = useFilterSelectedOptions(value ?? [], onChange);

  // Safety check to avoid showing an empty filter, likely never to happen.
  if (availableLicenses.length === 0) {
    return null;
  }

  return (
    <FiltersToggleButtonGroup
      value={selectedOptions}
      aria-label="Shift Licenses Filter Toggle Buttons"
      onChange={(newValue) => {
        const hasSelectedAllAvailableLicenses =
          newValue && availableLicenses.every((license) => newValue?.includes(license));

        if (hasSelectedAllAvailableLicenses) {
          onChangeSelection([FILTER_ALL_VALUE]);
        } else {
          onChangeSelection(newValue);
        }
      }}
    >
      <ToggleButton value={FILTER_ALL_VALUE}>All</ToggleButton>
      {availableLicenses.map((license) => (
        <ToggleButton
          key={license}
          value={license}
          selected={selectedOptions.includes(license) || selectedOptions.includes(FILTER_ALL_VALUE)}
        >
          {license}
        </ToggleButton>
      ))}
    </FiltersToggleButtonGroup>
  );
}
