import { Text } from "@clipboard-health/ui-react";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import pluralize from "pluralize";

import { Button } from "../../components/Button";
import { useVirtualShiftListContext } from "../../Shift/Open/useVirtualShiftListContext/useVirtualShiftListContext";
import { useShiftDiscoveryUserFiltersContext } from "./useUserFiltersContext";

interface ClearFiltersButtonProps {
  allOpenShiftsCount: number;
}

export function ClearFiltersButton(props: ClearFiltersButtonProps) {
  const { allOpenShiftsCount } = props;
  const { clearFilters, appliedFiltersCount } = useShiftDiscoveryUserFiltersContext();
  const { visibleOpenShiftsCount } = useVirtualShiftListContext();

  const hiddenOpenShiftsCount = allOpenShiftsCount - visibleOpenShiftsCount;

  if (appliedFiltersCount === 0) {
    return null;
  }

  return (
    <Button
      size="medium"
      variant="outlined"
      sx={{ gap: 1 }}
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_FILTERS_CLEARED, {
          trigger: "List View",
        });

        clearFilters();
      }}
    >
      <Text semibold variant="body2">
        Clear {pluralize("filter", appliedFiltersCount)}
      </Text>
      {hiddenOpenShiftsCount > 0 && (
        <Text semibold variant="caption" color={(theme) => theme.palette.text.tertiary}>
          +{hiddenOpenShiftsCount} {pluralize("shift", hiddenOpenShiftsCount)}
        </Text>
      )}
    </Button>
  );
}
